<template>
  <footer
    id="contact"
    ref="footerElement"
    class="global-footer"
    :class="{ '-visible': isSectionVisible, '-fully-visible': isSectionFullyVisible }"
  >
    <div class="backgrounds">
      <span class="background -red" />
      <span class="background -green" />
    </div>
    <div
      class="content"
    >
      <div class="column">
        <div class="offices-content">
          <a
            v-for="(office, index) in footer.address_set"
            :key="`office-${office.map_link}`"
            role="group"
            :href="office.map_link"
            target="_blank"
            class="office"
            rel="external"
          >
            <p
              class="title text-highlight -red"
              :class="{ '-visible': isSectionVisible, '-red': index % 2 === 0, '-green': index % 2 === 1 }"
            >
              {{ office.title }}
            </p>
            <p class="text">
              {{ office.street }}
            </p>
            <p class="text">
              {{ office.zipcode }} {{ office.city }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <div class="illustration">
      <span ref="wheelElement" class="wrapper">
        <RotatingGreetings />
      </span>
    </div>
  </footer>
</template>

<script setup lang="ts">

import { injectStrict } from '~/common/inject';
import { StatamicFooterInjectionKey } from '~/content/statamicFooter';
import RotatingGreetings from '~/common/svgs/RotatingGreetings.vue';
import { ControlPanelInjectionKey } from '~/common/useControlPanel';

const { footer } = injectStrict(StatamicFooterInjectionKey);
const { toggleFooterView } = injectStrict(ControlPanelInjectionKey);

const isSectionVisible = ref<boolean>(false);
const isSectionFullyVisible = ref<boolean>(false);
const footerElement = ref<HTMLElement | null>(null);
const wheelElement = ref<HTMLElement | null>(null);

const { stop: stopIO1 } = useIntersectionObserver(
  footerElement,
  ([{ isIntersecting }]) => {
    isSectionVisible.value = isIntersecting;
  },
  {
    threshold: 0.5
  }
);

const { stop: stopIO2 } = useIntersectionObserver(
  footerElement,
  ([{ isIntersecting }]) => {
    isSectionFullyVisible.value = isIntersecting;
  },
  {
    threshold: 0.9
  }
);

const runningOnLeave: (() => void)[] = [stopIO1, stopIO2];
const runningOnSectionVisible: (() => void)[] = [];
const runningOnSectionHidden: (() => void)[] = [];

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

watchEffect(() => {
  if (isSectionVisible.value) {
    toggleFooterView(true);
    runningOnSectionVisible.forEach((fn) => {
      fn();
    });
  } else {
    toggleFooterView(false);
    runningOnSectionHidden.forEach((fn) => {
      fn();
    });
  }
});

</script>

<style scoped lang="scss">

.global-footer {
  padding: 10vw 0 0 0;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 100vw;

  &.-visible {
    > .content {
      opacity: 1;
    }

    > .backgrounds {

      > .background {
        opacity: 1;
      }
    }
  }

  &:not(.-visible) {
    > .content {
      opacity: 0;
    }
  }

  &.-fully-visible {
    > .illustration {
      opacity: 1;
      transition: transform 550ms, opacity 150ms;
      transform: translateY(0) scale(1);
    }
  }

  &:not(.-fully-visible) {
    > .illustration {
      opacity: 0;
      transition: transform 350ms, opacity 150ms;
      transform: translateY(60vh) scale(0);
    }
  }

  > .backgrounds {
    width: 100vw;
    overflow: visible;
    pointer-events: none;
    position: fixed;
    inset: auto 0 0 0;
    z-index: -1;
    height: 70vh;

    > .background {
      --size: 100vw;

      @media (--portrait) {
        visibility: hidden;
      }

      @media (--landscape) {
        display: inline-block;
        width: var(--size);
        height: var(--size);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: opacity 500ms;

        &.-red {
          transition-delay: 0ms;
          transform: translate(-40%, 0%);
          background-image: url("/images/gradient-simple-red.webp");
        }

        &.-green {
          transition-delay: 0ms;
          transform: translate(40%, 0%);
          background-image: url("/images/gradient-simple-green.webp");
        }
      }
    }
  }

  > .content {
    width: 100%;
    max-width: 100vw;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);

    @media (--landscape) {
      padding-bottom: 70vh;
    }

    @media (--portrait) {
      padding-bottom: 30vh;
      height: var(--app-height);

      > .column {
        height: 100%;
      }
    }
  }

  > .illustration {
    position: fixed;
    width: 100vw;
    overflow: hidden;
    inset: auto auto 0 auto;

    @media (--lg-up) {
      max-height: 45vw;
    }

    @media (--md) {
      max-height: 40vw;
    }

    @media (--sm) {
      max-height: 65vw;
    }

    > .wrapper {
      display: flex;
      margin: auto;
      width: var(--radius);
      height: var(--radius);

      &:hover {
      }

      @media (--lg-up) {
        --radius: 68vw;
      }

      @media (--md) {
        --radius: 95vw;
      }

      @media (--sm) {
        --radius: 150vw;

        margin-right: -25vw;
        margin-left: -25vw;
      }
    }
  }
}

.offices-content {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (--lg-up) {
    @include text-xl;
  }

  @media (--md) {
    @include text-xl;
  }

  @media (--sm) {
    @include text-lg;
  }

  @media (--landscape) {
    flex-direction: row;
    height: fit-content;
  }

  @media (--portrait) {
    flex-direction: column;
    height: 100%;
  }

  > .office {
    @include fluid(--padding, 20);

    color: currentColor;
    text-decoration: none;
    padding: var(--padding) 0;
    display: flex;
    flex-direction: column;
    margin: auto 0;

    &:nth-child(2) {
      text-align: right;
      align-items: flex-end;
    }

    @media (--landscape) {
      &::before {
        content: unset;
      }
    }

    @media (--portrait) {
      &:nth-child(1) {
        --background-image: url("/images/gradient-simple-red.webp");

        &::before {
          left: -60%;
        }

      }

      &:nth-child(2) {
        --background-image: url("/images/gradient-simple-green.webp");

        text-align: right;
        align-items: flex-end;

        &::before {
          right: -60%;
        }
      }
    }

    &::before {
      --size: 100vw;

      content: "";
      display: inline;
      width: var(--size);
      height: var(--size);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      opacity: 1;
      pointer-events: none;
      transition: opacity 500ms;
      transition-delay: 0ms;
      background-image: var(--background-image);
      background-size: 120% 120%;

      @media (--portrait) {
        top: -2.5rem;
      }

      @media (--landscape) {
        top: -10rem;
      }
    }

    @media (--landscape) {
      height: 100%;
    }

    > .title {

    }

    > .text {

    }
  }
}

</style>
