import ContentBenefits from '~/cms-modules/ContentBenefits.vue';
import ContentFigures from '~/cms-modules/ContentFigures.vue';
import ContentImage from '~/cms-modules/ContentImage.vue';
import ContentImages from '~/cms-modules/ContentImages.vue';
import ContentText from '~/cms-modules/ContentText.vue';
import ContentTestimonials from '~/cms-modules/ContentTestimonials.vue';
import CtaDefault from '~/cms-modules/CtaDefault.vue';
import ProjectsSimple from '~/cms-modules/ProjectsSimple.vue';
import TheGridonicWay from '~/cms-modules/TheGridonicWay.vue';
import ContentLogoWall from '~/cms-modules/ContentLogoWall.vue';
import ProjectsBig from '~/cms-modules/ProjectsBig.vue';
import ContentVideo from '~/cms-modules/ContentVideo.vue';
import TeaserTeam from '~/cms-modules/TeaserTeam.vue';
import ContentTeamSlider from '~/cms-modules/ContentTeamSlider.vue';

export const modules = {
  content_figures: ContentFigures,
  content_benefits: ContentBenefits,
  content_image: ContentImage,
  content_video: ContentVideo,
  content_images: ContentImages,
  content_text: ContentText,
  content_testimonials: ContentTestimonials,
  content_logowall: ContentLogoWall,
  content_teamslider: ContentTeamSlider,
  cta_default: CtaDefault,
  teaser_team: TeaserTeam,
  projects_simple: ProjectsSimple,
  projects_big: ProjectsBig,
  the_gridonic_way: TheGridonicWay
};
