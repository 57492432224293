<template>
  <div
    ref="containerElement"
    class="teaser-team"
    :class="{ '-visible': isVisible }"
  >
    <figure v-if="data.video_inline" class="figure">
      <NuxtLink
        aria-label="Team"
        :to="localePath('/team')"
        class="link"
      >
        <video
          ref="videoElement"
          :poster="videoPoster ? videoPoster.default : ''"
          class="video"
          loading="lazy"
          preload="none"
          :src="useCloudfrontUrl(data.video_inline.permalink)"
          :muted="data.video_autoplay"
          :autoplay="data.video_autoplay"
          :loop="data.video_loop"
          :controls="data.video_controls"
          playsinline
        />
      </NuxtLink>
    </figure>
  </div>
</template>

<script setup lang="ts">

import { type StatamicFieldsetContentVideo } from '~/content/StatamicTypes';
import useCloudfrontUrl from '~/common/useCloudfrontUrl';
import useStatamicThumbnail from '~/content/useStatamicThumbnail';
import { injectStrict } from '~/common/inject';
import { ControlPanelInjectionKey } from '~/common/useControlPanel';

const props = defineProps<{
  data: StatamicFieldsetContentVideo;
}>();

const localePath = useLocalePath();

const containerElement = ref<HTMLElement | null>(null);
const videoElement = ref<HTMLElement | null>(null);
const isVisible = ref<boolean>(false);

const { stop } = useIntersectionObserver(
  containerElement,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;
    if (isIntersecting) {
      stop();
    }
  },
  {
    threshold: 0.3,
    rootMargin: '-100px'
  }
);

const videoPoster = computed(() => {
  const imageId = props.data.video_poster_image?.id;
  if (imageId) {
    return useStatamicThumbnail(imageId, null);
  }
  return null;
});

const runningOnLeave: (() => void)[] = [stop];

const { setControlPanelExtensionLink } = injectStrict(ControlPanelInjectionKey);

watchEffect(() => {
  if (isVisible.value) {
    setControlPanelExtensionLink({ title: 'team', route: '/team' });
  } else {
    setControlPanelExtensionLink(null);
  }
});

onMounted(async () => {
  await nextTick();
});

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

</script>

<style scoped lang="scss">

.teaser-team {
  width: 100%;
  max-width: 100vw;
  transition: opacity 550ms, inset 550ms;
  overflow-x: hidden;
  margin: auto;

  @include on-reduced-motion {
    --scale: 1;
    --inset-x: 5%;
    --inset-y: 5%;
  }

  @include on-full-motion {
    &.-visible {
      --scale: 1;
      --inset-x: 5%;
      --inset-y: 5%;
    }

    &:not(.-visible) {
      --inset-x: 15%;
      --inset-y: 15%;
      --scale: 0.8;
    }
  }

  >.figure {
    user-select: none;
    width: 100%;

    > .link {
      display: block;
      width: 100%;
      height: 80dvh;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
      }

      > .video {
        border-radius: var(--base-border-radius);
        width: 100vw;
        height: 100%;
        object-fit: cover;
        transform: scale(var(--scale));
        transition: transform 550ms, clip-path 550ms;
        clip-path: inset(var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round 8px);
      }
    }
  }
}

</style>
