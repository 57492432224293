<template>
  <div ref="logoWallElement" class="content-logo-wall">
    <h2 v-if="data.title" class="title">
      {{ data.title }}
    </h2>
    <div class="logo-wall" :class="{ '-visible': isSectionVisible }">
      <div v-for="(row, index) in dividedLogos" :key="index" class="marquee" :class="[`marquee-${index}`]">
        <div class="group">
          <figure v-for="(logo, i) in row" :key="i" class="figure">
            <img
              loading="lazy"
              class="logo"
              :style="{ transitionDelay: `${i * 100 + 100}ms`}"
              :src="useCloudfrontUrl(logo.permalink)"
              alt=""
            >
          </figure>
        </div>
        <div aria-hidden="true" class="group">
          <figure v-for="(logo, i) in row" :key="i" class="figure">
            <img
              loading="lazy"
              class="logo"
              :style="{ transitionDelay: `${i * 100 + 100}ms`}"
              :src="useCloudfrontUrl(logo.permalink)"
              alt=""
            >
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { type StatamicFieldsetContentLogoWall } from '~/content/StatamicTypes';
import useCloudfrontUrl from '~/common/useCloudfrontUrl';

const props = defineProps<{
  data: StatamicFieldsetContentLogoWall;
}>();

const { width } = useWindowSize();

const isSectionVisible = ref<boolean>(false);
const logoWallElement = ref<HTMLElement | null>(null);

const dividedLogos = computed(() => {
  const rows = 3;
  const dividedArray = Array.from({ length: rows }, () => []);

  for (let i = 0; i < props.data.logos.length; i++) {
    const rowIndex = i % rows;

    dividedArray[rowIndex].push(props.data.logos[i]);
  }

  return dividedArray;
});

const { stop } = useIntersectionObserver(
  logoWallElement,
  ([{ isIntersecting }]) => {
    isSectionVisible.value = isIntersecting;
  },
  {
    threshold: 0.3
  }
);
</script>

<style scoped lang="scss">
.content-logo-wall {
  @media (--lg-down) {
    padding: 3.75rem 0;
  }
  @media (--lg-up) {
    padding: 9rem 0;
  }

  > .title {
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);

    @media (--lg-down) {
      @include text-xl(500);
      @include fluid(padding-bottom, 36);
    }

    @media (--lg-up) {
      @include text-3xl(500);
      @include fluid(padding-bottom, 48);
    }
  }

  > .logos {

    > .logo {
    }
  }
}

.logo-wall {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin: auto;
  max-width: 100vw;
  padding: var(--page-padding) 0;

  @media (--sm) {
    min-height: calc(var(--app-height) * 0.2) 0;
  }

  &.-visible {
    > .marquee > .group > .figure > .logo {
      opacity: 1;
    }
  }

  &:not(.-visible) {
    > .marquee > .group > .figure > .logo {
      opacity: 0;
      transition-delay: 0ms !important;
    }
  }

  > .marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    mask-image: linear-gradient(to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );

    &.marquee-0 {
      > .group {
        animation-duration: 60000ms;
      }
    }

    &.marquee-1 {
      > .group {
        animation-duration: 90000ms;
        animation-direction: reverse;
        animation-delay: -3s;
      }
    }

    &.marquee-2 {
      > .group {
        animation-duration: 40000ms;
      }
    }

    > .group {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
      min-width: 100%;
      animation: marquee 60s linear infinite;

      > .figure {
        display: grid;
        //border: 1px solid yellow;
        height: 100%;

        @media (--sm) {
          @include fluid(width, 170);
        }

        @media (--md) {
          @include fluid(width, 200);
        }

        @media (--md-up) {
          @include fluid(width, 220);
        }

        > .logo {
          transition: opacity 550ms;
          filter: invert(1) grayscale(1) brightness(1.5);
        }
      }

      @media (prefers-reduced-motion: reduce) {
        animation-play-state: paused;
      }
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - clamp(10rem, 1rem + 40vmin, 30rem) / 14));
  }
}
</style>
