<template>
  <div class="projects-big">
    <ProjectTeaserBig
      v-for="(p, index) in projects"
      :key="`p-${p.id}`"
      :title="p.short_title"
      :image-left="p.assets_home.find(i => i.type === 'asset_left')?.asset ?? []"
      :image-right="p.assets_home.find(i => i.type === 'asset_right')?.asset ?? []"
      :short-title="p.short_title"
      :slug="p.slug"
      :position-text="index % 2 === 0 ? 'bottom' : 'top'"
    />
  </div>
</template>

<script setup lang="ts">
import { type StatamicFieldsetProjectsBig } from '~/content/StatamicTypes';
import { injectStrict } from '~/common/inject';
import { StatamicLinksInjectionKey, type StatamicProjectLink } from '~/content/statamicLinks';
import ProjectTeaserBig from '~/components/ProjectTeaserBig.vue';

const props = defineProps<{
  data: StatamicFieldsetProjectsBig;
}>();

const { links } = injectStrict(StatamicLinksInjectionKey);

const selectedProjectIds = computed((): string[] => {
  return props.data.projects.map(i => i.id);
});

const projects = computed((): StatamicProjectLink[] => {
  return selectedProjectIds.value.map((it) => {
    return links.projects.find(i => it === i.id);
  }).filter((i) => {
    return i !== undefined;
  }) as StatamicProjectLink[];
});

</script>

<style scoped lang="scss">

.projects-big {
  display: grid;
  grid-template-rows: repeat(var(--cols), auto);

  @media (--md-up) {
    padding-bottom: var(--page-padding);
  }
}
</style>
