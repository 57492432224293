<template>
  <div class="rotating-wheel">
    <div class="logo" />
    <div
      ref="wheel"
      class="wheel"
      @touchstart.passive="(e) => onTouchStart(e)"
      @touchmove.passive="(e) => onTouchMove(e)"
      @mousedown="(e) => onGrab(e.clientX, e.clientY)"
      @mousemove="(e) => onMouseMove(e)"
    >
      <svg
        class="svg"
        width="814"
        height="813"
        viewBox="0 0 814 813"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g ref="textInWheel" class="text">
          <path
            d="M722.469 428.063C723.34 414.807 731.122 407.676 743.968 408.52L743.35 417.93C735.904 417.441 731.125 421.4 730.657 428.519C730.249 434.737 732.887 438.362 738.042 438.7C742.378 438.985 744.722 437.085 747.765 430.793L752.141 421.712C755.396 414.694 760.798 411.269 768.081 411.747C777.409 412.36 783.296 420.307 782.587 431.108C781.84 442.481 774.227 449.541 763.426 448.832L764.044 439.422C770.345 439.836 774.171 436.636 774.59 430.254C774.944 424.853 772.367 421.561 768.03 421.276C764.103 421.018 761.301 423.628 758.161 430.161L754.17 438.363C750.752 445.37 745.442 448.637 737.669 448.126C727.85 447.481 721.722 439.436 722.469 428.063Z"
            fill="white"
          />
          <path
            d="M717.767 467.23C720.21 455.175 730.176 450.585 741.187 452.816C752.197 455.047 759.46 463.798 757.098 475.451C756.088 480.434 752.926 484.478 748.677 486.043L753.74 487.069L751.981 495.749L714.53 488.16L716.289 479.48L721.352 480.506C718.047 477.41 716.839 471.811 717.767 467.23ZM770.2 464.468C773.656 465.169 775.835 468.455 775.151 471.83C774.451 475.286 771.164 477.465 767.709 476.764C764.333 476.08 762.155 472.794 762.855 469.338C763.539 465.963 766.825 463.784 770.2 464.468ZM739.412 461.576C732.58 460.192 726.687 463.264 725.302 470.095C723.788 477.569 728.52 482.293 734.949 483.596C741.379 484.899 747.576 482.39 749.091 474.916C750.475 468.085 746.243 462.96 739.412 461.576ZM766.894 480.783C770.35 481.483 772.529 484.769 771.845 488.145C771.145 491.6 767.858 493.779 764.403 493.079C761.027 492.395 758.849 489.109 759.549 485.653C760.233 482.277 763.519 480.099 766.894 480.783Z"
            fill="white"
          />
          <path d="M711.449 497.143L766.243 514.243L763.58 522.775L708.786 505.675L711.449 497.143Z" fill="white" />
          <path
            d="M699.62 527.501C702.907 519.81 709.719 516.39 719.144 520.418L741.237 529.86L737.725 538.079L717.667 529.507C712.012 527.09 707.921 527.482 705.633 532.836C702.959 539.094 706.139 543.129 711.191 545.288L731.022 553.763L727.509 561.982L692.372 546.965L695.884 538.746L700.785 540.841C697.917 537.118 697.461 532.553 699.62 527.501Z"
            fill="white"
          />
          <path
            d="M675.945 603.887C679.048 599.654 685.008 598.736 689.24 601.839C693.539 604.99 694.457 610.949 691.355 615.182C688.204 619.481 682.244 620.399 677.945 617.248C673.712 614.145 672.794 608.185 675.945 603.887Z"
            fill="white"
          />
          <path
            d="M629.452 634.236L651.73 690.565L645.405 695.912L593.57 664.57L601.397 657.952L607.8 662.097L624.645 647.856L621.624 640.853L629.452 634.236ZM628.219 655.894L615.194 666.906L640.076 683.13L628.219 655.894Z"
            fill="white"
          />
          <path
            d="M592.997 711.01C585.23 715.912 578.451 714.082 573.112 705.622L560.289 685.304L567.847 680.533L578.482 697.384C582.815 704.249 586.5 706.093 591.77 702.767C596.693 699.66 597.41 694.65 593.821 688.964L582.618 671.212L590.176 666.441L620.812 714.982L613.253 719.753L600.167 699.019C600.345 703.755 597.782 707.991 592.997 711.01Z"
            fill="white"
          />
          <path
            d="M536.711 695.388C548.209 690.167 558.74 695.112 563.385 705.34C568.029 715.569 564.822 726.752 553.324 731.973C541.826 737.194 531.295 732.25 526.65 722.021C522.006 711.792 525.213 700.609 536.711 695.388ZM555.246 709.036C552.364 702.69 546.57 699.647 540 702.631C533.429 705.614 531.907 711.979 534.789 718.325C537.67 724.672 543.465 727.714 550.035 724.731C556.606 721.747 558.128 715.382 555.246 709.036Z"
            fill="white"
          />
          <path
            d="M512.125 706.316L524.331 742.526L515.939 745.355L503.733 709.145L512.125 706.316ZM530.305 753.319C531.484 756.816 529.863 760.477 526.133 761.735C522.403 762.992 518.896 761.059 517.717 757.562C516.539 754.066 518.16 750.404 521.89 749.147C525.62 747.889 529.127 749.822 530.305 753.319Z"
            fill="white"
          />
          <path
            d="M461.291 759.267C452.232 760.78 446.69 756.469 445.042 746.601L441.083 722.904L449.899 721.431L453.182 741.085C454.52 749.092 457.203 752.218 463.35 751.192C469.093 750.232 471.693 745.891 470.585 739.259L467.126 718.553L475.942 717.081L485.399 773.696L476.584 775.169L472.544 750.986C470.874 755.422 466.871 758.335 461.291 759.267Z"
            fill="white"
          />
          <path
            d="M415.436 723.097C428.058 722.707 435.872 731.326 436.219 742.554C436.565 753.783 429.298 762.868 416.676 763.258C404.054 763.647 396.241 755.029 395.894 743.8C395.547 732.571 402.814 723.487 415.436 723.097ZM427.285 742.83C427.07 735.864 422.894 730.824 415.682 731.047C408.469 731.27 404.612 736.557 404.828 743.524C405.043 750.491 409.218 755.53 416.431 755.307C423.643 755.085 427.5 749.797 427.285 742.83Z"
            fill="white"
          />
          <path
            d="M387.328 723.655L384.53 761.764L375.698 761.116L378.496 723.007L387.328 723.655ZM385.849 774.03C385.579 777.71 382.664 780.456 378.739 780.168C374.813 779.88 372.331 776.737 372.601 773.057C372.871 769.377 375.786 766.631 379.712 766.919C383.637 767.208 386.12 770.35 385.849 774.03Z"
            fill="white"
          />
          <path
            d="M317.835 728.346C322.895 729.738 325.875 734.98 324.484 740.041C323.07 745.18 317.828 748.16 312.768 746.768C307.629 745.355 304.648 740.112 306.062 734.973C307.454 729.913 312.696 726.933 317.835 728.346Z"
            fill="white"
          />
          <path
            d="M247.253 679.835C262.856 689.053 266.192 705.976 257.572 720.408C249.368 734.133 233.161 741.416 215.934 731.24C203.862 724.108 199.531 711.93 203.939 702.534L212.835 707.789C210.974 713.357 213.17 719.797 220.23 723.968C232.303 731.1 243.052 725.64 249.029 715.361C255.147 705.166 253.406 693.28 242.957 687.107C234.838 682.311 227.233 682.771 220.669 689.369L230.2 695L225.904 702.272L202.394 688.383L206.69 681.111L212.126 684.323C221.354 674.345 234.121 672.078 247.253 679.835Z"
            fill="white"
          />
          <path
            d="M214.454 659.728L190.537 689.529L183.566 683.934L188.185 678.179C183.711 681.527 178.59 680.571 175.328 677.954C174.241 677.081 173.397 676.299 172.784 675.491L177.916 669.096C178.901 670.096 179.476 670.558 180.563 671.431C185.488 675.383 191.47 674.086 196.911 667.307L207.484 654.133L214.454 659.728Z"
            fill="white"
          />
          <path
            d="M183.938 631.845C189.83 637.781 190.441 645.379 183.166 652.6L166.116 669.527L159.819 663.184L175.298 647.817C179.663 643.484 180.837 639.545 176.735 635.414C171.94 630.584 167.006 632.015 163.107 635.886L147.802 651.08L141.505 644.737L168.623 617.815L174.92 624.158L171.138 627.913C175.665 626.654 180.067 627.946 183.938 631.845ZM155.657 675.519C153.155 678.004 149.212 677.989 146.786 675.545C144.301 673.043 144.316 669.1 146.818 666.616C149.262 664.189 153.205 664.204 155.689 666.706C158.116 669.15 158.101 673.093 155.657 675.519ZM143.929 663.706C141.427 666.19 137.484 666.176 135.058 663.732C132.574 661.23 132.588 657.287 135.09 654.803C137.534 652.376 141.477 652.391 143.961 654.893C146.388 657.337 146.374 661.28 143.929 663.706Z"
            fill="white"
          />
          <path
            d="M154.041 597.794C161.379 607.358 159.806 617.97 149.982 625.507C140.288 632.944 129.451 631.751 122.363 622.513C115.724 613.86 117.087 603.512 125.61 596.974C126.846 596.025 127.871 595.342 129.663 594.174L146.933 616.684C151.598 612.795 151.804 607.676 147.861 602.536C145.166 599.023 142.462 597.79 139.222 598.416L133.731 591.259C140.09 587.827 148.451 590.508 154.041 597.794ZM141.533 620.827L129.454 605.083C124.82 608.742 124.314 613.471 128.058 618.35C131.901 623.36 137.19 624.056 141.533 620.827Z"
            fill="white"
          />
          <path
            d="M133.166 566.659C137.92 574.707 136.764 583.39 130.481 587.102L125.935 579.407C128.759 577.738 129.109 573.817 126.773 569.863C125.063 566.969 122.426 566.051 120.096 567.427C118.26 568.511 117.589 569.955 118.757 574.028L120.871 581.636C122.311 586.976 120.536 591.549 115.876 594.301C110.298 597.596 103.076 594.72 98.738 587.377C93.9834 579.329 94.947 572.093 101.301 568.339L105.764 575.894C103.01 577.52 102.638 580.598 104.765 584.198C106.391 586.952 108.971 588.094 110.948 586.927C112.713 585.884 113.288 584.116 112.341 580.579L110.369 572.887C108.958 567.435 110.595 562.468 115.255 559.715C121.679 555.92 128.453 558.681 133.166 566.659Z"
            fill="white"
          />
          <path
            d="M117.137 535.588C120.97 544.114 118.858 552.616 112.202 555.608L108.537 547.456C111.529 546.111 112.311 542.253 110.428 538.065C109.05 534.999 106.53 533.794 104.062 534.903C102.118 535.778 101.291 537.138 102 541.315L103.257 549.111C104.097 554.577 101.826 558.925 96.8897 561.144C90.9814 563.801 84.1217 560.142 80.6247 552.364C76.7915 543.838 78.5511 536.753 85.2821 533.727L88.88 541.729C85.9632 543.041 85.2518 546.058 86.9667 549.872C88.2781 552.789 90.7153 554.21 92.8094 553.269C94.6791 552.428 95.4462 550.735 94.8978 547.115L93.7899 539.252C92.9916 533.677 95.1692 528.922 100.105 526.703C106.911 523.643 113.337 527.137 117.137 535.588Z"
            fill="white"
          />
          <path
            d="M103.712 499.961C107.303 511.467 102.168 520.887 90.3477 524.575C78.6845 528.215 68.9225 523.359 65.4538 512.243C62.2049 501.832 67.0513 492.589 77.3056 489.389C78.7929 488.925 79.9914 488.637 82.0755 488.158L90.5275 515.242C96.2467 513.2 98.2053 508.465 96.2755 502.281C94.9564 498.054 92.844 495.965 89.5863 495.435L86.8993 486.825C94.0513 485.795 100.976 491.194 103.712 499.961ZM84.0305 517.269L78.119 498.326C72.5075 500.164 70.4024 504.428 72.2345 510.299C74.1154 516.326 78.8398 518.803 84.0305 517.269Z"
            fill="white"
          />
          <path
            d="M94.4948 461.865C96.5883 473.569 89.7726 482.035 78.0683 484.129C66.3641 486.223 57.0361 480.644 54.9425 468.94C53.0655 458.446 57.6271 450.883 66.1196 448.531L67.6934 457.329C63.5886 459.063 61.8193 462.212 62.7723 467.539C63.884 473.754 68.8263 476.702 76.4946 475.331C84.1629 473.959 87.7768 469.481 86.665 463.265C85.7121 457.938 83.0418 455.583 78.9133 455.322L77.3395 446.524C85.8787 445.829 92.6178 451.371 94.4948 461.865Z"
            fill="white"
          />
          <path
            d="M49.6582 419.079C49.233 409.905 54.1733 404.915 64.1666 404.452L88.1669 403.339L88.5806 412.268L68.676 413.19C60.5667 413.566 57.1431 415.859 57.4316 422.084C57.7011 427.9 61.7029 430.998 68.4196 430.687L89.3891 429.715L89.8029 438.643L32.4644 441.301L32.0507 432.372L56.5424 431.237C52.3367 429.052 49.9201 424.731 49.6582 419.079Z"
            fill="white"
          />
          <path
            d="M78.4782 343.371C77.4949 348.526 72.5075 351.915 67.3524 350.932C62.1168 349.934 58.7272 344.946 59.7105 339.791C60.7091 334.556 65.6965 331.166 70.9321 332.165C76.0872 333.148 79.4768 338.135 78.4782 343.371Z"
            fill="white"
          />
          <path
            d="M113.991 281.504L69.6423 261.763L62.873 276.971L54.7823 273.369L72.1891 234.265L80.2797 237.866L73.4771 253.148L117.826 272.889L113.991 281.504Z"
            fill="white"
          />
          <path
            d="M134.117 244.18C129.333 252.211 121.159 255.359 114.889 251.624L119.463 243.945C122.281 245.624 125.89 244.052 128.24 240.107C129.961 237.218 129.502 234.463 127.177 233.079C125.346 231.987 123.757 232.091 120.743 235.068L115.079 240.57C111.083 244.394 106.219 245.028 101.57 242.258C96.0044 238.943 95.0659 231.225 99.4304 223.899C104.215 215.868 111.027 213.244 117.367 217.021L112.877 224.559C110.129 222.923 107.25 224.071 105.11 227.664C103.473 230.411 103.707 233.223 105.679 234.398C107.441 235.447 109.268 235.104 111.918 232.578L117.723 227.16C121.831 223.308 126.975 222.363 131.624 225.133C138.035 228.952 138.86 236.22 134.117 244.18Z"
            fill="white"
          />
          <path
            d="M154.989 213.415C147.752 222.848 136.903 223.516 127.47 216.279C118.036 209.041 115.873 198.39 123.111 188.956C129.6 180.499 138.262 178.773 145.722 183.463L140.282 190.555C136.235 188.69 132.716 189.504 129.421 193.798C125.578 198.808 126.73 204.446 132.911 209.188C139.091 213.929 144.835 213.582 148.679 208.573C151.973 204.279 151.914 200.719 149.325 197.493L154.765 190.401C161.032 196.243 161.478 204.957 154.989 213.415Z"
            fill="white"
          />
          <path
            d="M156.703 151.027C163.197 144.533 170.213 144.823 177.287 151.897L194.276 168.886L187.955 175.206L173.866 161.116C168.125 155.376 164.125 154.39 159.718 158.797C155.601 162.913 156.007 167.958 160.762 172.713L175.605 187.556L169.285 193.876L128.697 153.288L135.017 146.968L152.354 164.305C151.136 159.724 152.702 155.028 156.703 151.027Z"
            fill="white"
          />
          <path
            d="M214.385 154.151C204.338 161.802 192.971 159.326 186.165 150.388C179.36 141.45 179.997 129.834 190.043 122.184C200.09 114.534 211.457 117.009 218.263 125.947C225.069 134.885 224.432 146.501 214.385 154.151ZM193.276 144.973C197.499 150.519 203.825 152.195 209.566 147.823C215.308 143.451 215.375 136.907 211.152 131.362C206.93 125.816 200.603 124.141 194.862 128.512C189.121 132.884 189.054 139.428 193.276 144.973Z"
            fill="white"
          />
          <path
            d="M247.411 132.023C240.132 136.144 232.642 134.731 227.592 125.811L215.755 104.904L223.533 100.5L234.279 119.481C237.309 124.833 240.799 127.003 245.865 124.135C251.788 120.782 251.707 115.645 249 110.864L238.375 92.097L246.153 87.6934L264.979 120.946L257.201 125.35L254.575 120.711C254.597 125.41 252.191 129.317 247.411 132.023Z"
            fill="white"
          />
          <path
            d="M297.474 46.8657L305.887 44.1002L323.811 98.6297L315.398 101.395L313.785 96.4876C312.729 100.891 308.461 104.711 304.021 106.171C292.336 110.012 283.416 103.622 279.908 92.9496C276.4 82.2774 280.412 71.6365 291.707 67.9236C296.537 66.336 301.616 67.0832 305.079 70.0018L297.474 46.8657ZM288.399 90.1585C290.576 96.7799 296.162 100.382 302.783 98.2051C310.028 95.8237 311.792 89.3742 309.743 83.1423C307.695 76.9103 302.448 72.7655 295.204 75.1469C288.582 77.3235 286.222 83.5371 288.399 90.1585Z"
            fill="white"
          />
          <path
            d="M346.455 94.9016C338.221 96.3744 331.624 92.5563 329.819 82.4665L325.589 58.8159L334.387 57.2421L338.228 78.7133C339.31 84.7672 341.883 87.9723 347.614 86.9472C354.314 85.7488 355.941 80.8761 354.974 75.468L351.177 54.2389L359.975 52.6651L366.703 90.2801L357.905 91.8539L356.966 86.6072C355.428 91.0474 351.863 93.9343 346.455 94.9016Z"
            fill="white"
          />
          <path
            d="M423.816 72.6048C418.576 72.3214 414.549 67.8334 414.832 62.5931C415.12 57.2709 419.608 53.2433 424.848 53.5267C430.17 53.8145 434.198 58.3025 433.91 63.6247C433.627 68.865 429.139 72.8926 423.816 72.6048Z"
            fill="white"
          />
          <path
            d="M479.082 95.7262L495.974 40.8681L504.986 43.6433L497.723 67.2322L520.371 74.2063L527.635 50.6174L536.647 53.3925L519.755 108.251L510.743 105.475L517.886 82.2783L495.237 75.3042L488.094 98.5014L479.082 95.7262Z"
            fill="white"
          />
          <path
            d="M542.235 119.595C531.172 114.22 529.215 103.424 534.125 93.3191C539.034 83.2146 549.321 78.3666 560.015 83.5626C564.588 85.7844 567.715 89.8563 568.17 94.362L570.428 89.7154L578.393 93.5856L561.694 127.956L553.729 124.085L555.986 119.439C552.163 121.866 546.439 121.638 542.235 119.595ZM542.164 97.2251C539.118 103.494 540.622 109.968 546.891 113.014C553.751 116.347 559.506 112.944 562.373 107.044C565.24 101.143 564.358 94.5154 557.498 91.1828C551.229 88.1368 545.21 90.9558 542.164 97.2251Z"
            fill="white"
          />
          <path d="M568.441 132.379L598.54 83.5035L606.151 88.1903L576.052 137.066L568.441 132.379Z" fill="white" />
          <path d="M585.554 143.276L618.638 96.3695L625.942 101.521L592.858 148.428L585.554 143.276Z" fill="white" />
          <path
            d="M613.44 165.98C603.885 157.723 603.968 146.089 611.314 137.59C618.66 129.09 630.158 127.323 639.713 135.58C649.267 143.837 649.184 155.47 641.838 163.97C634.493 172.47 622.994 174.237 613.44 165.98ZM618.077 143.434C613.519 148.708 613.181 155.243 618.641 159.962C624.1 164.68 630.518 163.399 635.076 158.126C639.633 152.852 639.971 146.316 634.512 141.598C629.052 136.879 622.634 138.161 618.077 143.434Z"
            fill="white"
          />
          <path
            d="M690.749 189.723C696.393 196.969 695.239 203.895 687.347 210.043L668.392 224.806L662.9 217.755L678.62 205.511C685.025 200.522 686.496 196.674 682.667 191.757C679.089 187.164 674.033 186.944 668.729 191.076L652.167 203.975L646.675 196.924L691.96 161.653L697.452 168.704L678.109 183.77C682.805 183.127 687.272 185.259 690.749 189.723Z"
            fill="white"
          />
          <path
            d="M680.589 246.646C674.496 236.245 677.375 225.911 688.059 219.652C698.602 213.477 709.207 216.007 715.093 226.054C720.605 235.465 717.967 245.563 708.699 250.992C707.354 251.78 706.252 252.331 704.329 253.267L689.988 228.786C684.877 232.065 684.036 237.119 687.311 242.709C689.549 246.53 692.078 248.089 695.371 247.871L699.93 255.654C693.194 258.269 685.231 254.571 680.589 246.646ZM695.861 225.346L705.891 242.468C710.944 239.413 712.033 234.784 708.925 229.477C705.734 224.029 700.572 222.681 695.861 225.346Z"
            fill="white"
          />
          <path
            d="M738.575 276.542L742.026 284.787L706.626 299.604C701.104 301.915 696.247 301.992 692.138 300.245C688.288 298.479 685.141 294.996 683.051 290.004C680.866 284.785 680.443 280.162 681.616 276.382C682.765 272.967 685.296 270.308 689.122 268.617L692.605 276.938C688.906 278.931 687.579 281.708 689.795 287.003C691.853 291.92 696.291 294.24 703.78 291.106L708.243 289.238C703.543 288.627 699.854 285.549 697.669 280.329C694.028 271.631 698.426 264.723 706.898 261.177L728.38 252.185L731.831 260.43L712.089 268.694C706.869 270.878 703.664 274.264 706.134 280.164C708.54 285.913 714.294 286.705 720.345 284.172L738.575 276.542Z"
            fill="white"
          />
          <path
            d="M736.056 350.536C735.191 345.359 738.695 340.451 743.871 339.586C749.128 338.708 754.036 342.211 754.901 347.388C755.779 352.645 752.276 357.553 747.019 358.431C741.843 359.296 736.934 355.793 736.056 350.536Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_794_35232">
            <rect width="51" height="64" fill="white" transform="translate(380.451 374.066)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const wheel = ref<HTMLElement | null>(null);
const textInWheel = ref<HTMLElement | null>(null);

let wheelX = 0;
let wheelY = 0;
let currentAngle = 0;
let startAngle = 0;
let oldAngle = 0;
const lastAngles = [0, 0, 0];
let isSpinning = false;
let isDragging = false;

onMounted(() => {
  if (wheel.value) {
    window.addEventListener('mouseup', () => onRelease(wheel.value as HTMLElement));
    window.addEventListener('touchend', () => onRelease(wheel.value as HTMLElement));
    window.addEventListener('resize', () => onResize());

    calculatePositions(wheel.value);
  }
});

function onTouchStart (e: TouchEvent) {
  onGrab(e.touches[0].clientX, e.touches[0].clientY);
}

function onTouchMove (e: TouchEvent) {
  onMove(e.touches[0].clientX, e.touches[0].clientY);
}

function onMouseMove (e: MouseEvent) {
  if (e.which === 1) { // detect left mouse button
    onMove(e.clientX, e.clientY);
  } else if (!isDragging) {
    onRelease();
  }
}

function calculatePositions (wheel: HTMLElement) {
  const wheelWidth = wheel.getBoundingClientRect().width;
  const wheelHeight = wheel.getBoundingClientRect().height;
  wheelX = wheel.getBoundingClientRect().x + wheelWidth / 2; // left
  wheelY = wheel.getBoundingClientRect().y + wheelHeight / 2; // top
}

function onGrab (x: number, y: number) {
  isDragging = true;
  startAngle = calculateAngle(x, y);
  if (textInWheel.value) {
    textInWheel.value.style.animationPlayState = 'paused';
  }
}

function onMove (x: number, y: number) {
  if (!isDragging) {
    return;
  }

  lastAngles.shift();
  lastAngles.push(currentAngle);

  const deltaAngle = calculateAngle(x, y) - startAngle;
  currentAngle = deltaAngle + oldAngle;

  render(currentAngle);
}

function isClockwise (oldAngle: number, newAngle: number) {
  return (oldAngle < newAngle) !== (Math.abs(oldAngle - newAngle) < 180);
}

function onRelease (wheel: HTMLElement) {
  if (isDragging) {
    isDragging = false;
    oldAngle = currentAngle;

    if (!isClockwise(lastAngles[2], currentAngle)) {
      wheel.classList.add('-rotate-right');
      wheel.classList.remove('-rotate-left');
    } else {
      wheel.classList.add('-rotate-left');
      wheel.classList.remove('-rotate-right');
    }

    const speed = lastAngles[0] - lastAngles[2];
    giveMoment(speed);
  }

  if (textInWheel.value) {
    textInWheel.value.style.animationPlayState = 'running';
  }
}

function onResize () {
  if (wheel.value) {
    calculatePositions(wheel.value);
  }
}

// calculate angle and convert it to degree
function calculateAngle (currentX: number, currentY: number) {
  const xLength = currentX - wheelX;
  const yLength = currentY - wheelY;
  // convert rad (angle) to in deg
  const angle = Math.atan2(yLength, xLength) * (180 / Math.PI);
  return angle;
}

// reduces speed over time
// -0.07 per AF (60 per second) linear
// change speed decrament
function giveMoment (speed: number) {
  const maxSpeed = 6;

  if (speed >= maxSpeed) {
    speed = maxSpeed;
  } else if (speed <= -maxSpeed) {
    speed = -maxSpeed;
  }

  if (speed >= 0.1) {
    speed -= 0.1;
    // @ts-ignore
    window.requestAnimationFrame(giveMoment.bind(this, speed));
    isSpinning = true;
  } else if (speed <= -0.1) {
    speed += 0.1;
    // @ts-ignore
    window.requestAnimationFrame(giveMoment.bind(this, speed));
    isSpinning = true;
  } else {
    isSpinning = false;
  }

  oldAngle -= speed;
  render(oldAngle);
}

function render (deg: number) {
  (wheel.value as HTMLElement).style.transform = `rotate(${deg}deg)`;
}
</script>

<style scoped lang="scss">
.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 51px;
  height: 64px;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg width='51' height='64' viewBox='0 0 51 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_919_18263)'%3E%3Cpath d='M9.24074 26.4775L4.61987 22.1187L-0.000994498 26.4775L4.61987 30.8364L9.24074 26.4775Z' fill='white'/%3E%3Cpath d='M51.1917 26.4863L46.5708 22.1274L41.9499 26.4863L46.5708 30.8452L51.1917 26.4863Z' fill='white'/%3E%3Cpath d='M41.9508 37.5197L30.238 26.4687L41.9388 15.4313L25.5775 -0.00244141L9.24023 15.4063L32.6826 37.5174L25.5871 44.2106L13.8743 33.1596L9.24264 37.5287L20.9578 48.5797L9.31001 59.5626L13.9489 63.9363L25.5967 52.9511L37.3071 64.0021L41.9388 59.633L30.226 48.5843L41.9508 37.5197ZM18.5205 15.4086L25.5919 8.73804L32.6754 15.4313L25.6039 22.0996L18.5205 15.4086Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_919_18263'%3E%3Crect width='51' height='64' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 51px 64px;
  background-position: center;

  @media (--lg-down) {
    visibility: hidden;
  }
}

.rotating-wheel {
  cursor: grab;
  margin: auto;

  > .wheel {
    margin: auto;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.-rotate-right {
      > .svg > .text {
        animation: rotate-right 100000ms linear;
      }
    }

    &.-rotate-left {
      > .svg > .text {
        animation: rotate-left 100000ms linear;
      }
    }

    > .svg {
      margin: auto;
      width: 100%;
      height: 100%;
      touch-action: none;
      pointer-events: all;

      > .text {
        animation-duration: 100000ms;
        animation: rotate-right 100000ms linear;
        transform-origin: center;
        animation-iteration-count: infinite;
      }
    }
  }

  @keyframes rotate-right {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(0);
    }
  }

  @keyframes rotate-left {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(-360deg);
    }
  }
}
</style>
