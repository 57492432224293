<template>
  <div ref="containerElement" class="content-team-slider">
    <h2 v-if="data.title" class="title">
      {{ data.title }}
    </h2>
    <div ref="sliderElement" class="members" :style="{ '--count': featuredPeople.length }">
      <div v-for="(m, index) in featuredPeople" :key="`team-member-${index}-${m.title}`" class="member">
        <StatamicImage
          v-for="a in m.portrait_asset"
          :id="a.asset.id"
          :key="`portrait_asset-${a.asset.id}`"
          :id-mobile="a.asset.id"
          class="asset"
          cropping="3:4"
          alt=""
        />
        <h3 class="title">
          {{ m.title }}<br>
        </h3>
        <p class="role">
          {{ m.role }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { type StatamicFieldsetContentTeamSlider } from '~/content/StatamicTypes';
import { injectStrict } from '~/common/inject';
import { StatamicTeamInjectionKey } from '~/content/statamicTeam';
import StatamicImage from '~/common/StatamicImage.vue';

const props = defineProps<{
  data: StatamicFieldsetContentTeamSlider;
}>();

const containerElement = ref<HTMLElement | null>(null);
const sliderElement = ref<HTMLElement | null>(null);

const runningOnLeave: (() => void)[] = [];

const { teamMembers } = injectStrict(StatamicTeamInjectionKey);

const featuredPeople = computed(() => {
  return props.data.team_members.map(({ id }) => {
    return teamMembers.find(p => p.id === id);
  })
    .filter(i => !!i)
    .filter(i => i && i.portrait_asset.length > 0);
});

onMounted(function () {
  if (sliderElement.value && containerElement.value) {
    const animation = sliderAnimation(sliderElement.value);

    runningOnLeave.push(animation.kill);
  }
});

onBeforeUnmount(() => {
  runningOnLeave
    .forEach((fn) => {
      fn();
    });
});

function sliderAnimation (sliderElement: HTMLElement) {
  const tl = gsap.timeline({
    paused: true
  });

  tl.to(sliderElement, {
    x: () => -(sliderElement?.scrollWidth - window.innerWidth) + 'px',
    ease: 'none',
    scrollTrigger: {
      trigger: sliderElement,
      end: () => '+=' + sliderElement.offsetWidth,
      scrub: 0.5,
      pin: true,
      invalidateOnRefresh: true
    }
  });

  return { kill };
  function kill () {
    tl.kill();
  }
}

</script>

<style scoped lang="scss">
.content-team-slider {
  @media (--lg-down) {
    padding: 3.75rem var(--page-padding);
  }
  @media (--lg-up) {
    padding: 9rem var(--page-padding);
  }

  > .title {
    @media (--lg-down) {
      @include text-xl(500);
    }

    @media (--lg-up) {
      @include text-3xl(500);
    }
  }

  .members {
    --count: 1;

    width: calc(25vw + var(--count) * calc(var(--teaser-width) + var(--gap)));
    display: flex;
    gap: var(--gap);
    height: 100vh;
    align-items: center;
    padding-right: 50vw;

    @media (--sm) {
      --teaser-width: 75vw;
      --gap: 2.5rem;
    }
    @media (--md) {
      --teaser-width: 40vw;
      --gap: 4rem;
    }
    @media (--lg-up) {
      --teaser-width: 25vw;
      --gap: 5rem;
    }

    > .member {
      width: var(--teaser-width);
      flex-shrink: 0;

      @media (--sm) {
        @include text-xs;
      }

      @media (--md-up) {
        @include text-sm;
      }

      > .asset {
        width: 100%;
        height: 100%;
        border-radius: var(--base-border-radius);
        overflow: clip;
      }

      > .title {
        padding-top: 0.5rem;

        @media (--sm) {
          @include text-xs;
        }

        @media (--md-up) {
          @include text-sm;
        }
      }

      > .role {
        color: rgba(198, 198, 198, 1);

        @media (--sm) {
          @include text-xs(500);
        }

        @media (--md-up) {
          @include text-sm(500);
        }
      }
    }
  }
}
</style>
