<template>
  <div class="content-text">
    <div class="container">
      <div
        ref="textElement"
        class="text text-highlight-content"
        :class="[{ '-visible': isSectionVisible }, `-${textGradient}`]"
        v-html="data.text"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import animateOnReady from '~/common/animateOnReady';
import { type StatamicFieldsetContentText } from '~/content/StatamicTypes';

const props = defineProps<{
  data: StatamicFieldsetContentText;
}>();

const textGradient = props.data.gradient?.value ?? '';

const isSectionVisible = ref<boolean>(false);
const textElement = ref<HTMLElement | null>(null);

const runningOnLeave: (() => void)[] = [];

const { stop } = useIntersectionObserver(
  textElement,
  ([{ isIntersecting }]) => {
    isSectionVisible.value = isIntersecting;
  },
  {
    threshold: 0,
    rootMargin: '-100px'
  }
);

onMounted(() => {
  if (textElement.value) {
    const { $Splitting } = useNuxtApp();

    $Splitting({
      target: textElement.value,
      by: 'words',
      key: null
    });

    const words = Array.from(textElement.value.querySelectorAll('.word'));
    const { start, kill } = animateWords(textElement.value, words);
    animateOnReady(start);
    runningOnLeave.push(kill);
  }
});

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

function animateWords (textElement: HTMLElement, words: Element[]) {
  return { start, kill };

  function start () {
    gsap.fromTo(words, {
      'will-change': 'opacity',
      opacity: 0.1
    },
    {
      ease: 'none',
      opacity: 1,
      stagger: 0.05,
      scrollTrigger: {
        trigger: textElement,
        start: 'top bottom-=20%',
        end: 'center top+=20%',
        scrub: true
      }
    });
  }

  function kill () {
    gsap.killTweensOf(words);
  }
}

</script>

<style scoped lang="scss">
.content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (--lg-up) {
    min-height: 80dvh;
  }

  > .container {
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    margin: auto 0;

    > .text {
      color: #ffffff;
      max-width: 50ch;
      padding-top: 3rem;
      padding-bottom: 3rem;

      @media (--sm) {
        @include fluid(--height, 28);
        @include fluid(--translate, 50);
        @include text-lg(400);

        line-height: 1.65;
      }

      @media (--md) {
        @include fluid(--height, 40);
        @include fluid(--translate, 70);
        @include text-xl(400);

        line-height: 1.65;
      }

      @media (--lg-up) {
        @include fluid(--height, 50);
        @include fluid(--translate, 90);
        @include text-2xl(400);

        line-height: 1.65;
      }

      > :deep(p) {
        @include fluid(padding-top, 16);
        @include fluid(padding-bottom, 16);
      }

      :deep(a){
        text-decoration: none;
      }

      &.-visible {
        > .group {
          > .line {
            transform: translateY(0);
          }
        }
      }

      &:not(.-visible) {
        > .group {
          > .line {
            transform: translateY(var(--translate));
            transition-delay: 0ms !important;
          }
        }
      }

      > .group {
        max-width: var(--content-width);
        display: block;
        height: var(--height);
        overflow-y: hidden;
        overflow-x: hidden;
        white-space: nowrap;

        > .line {
          display: inline-block;
          transition: transform 750ms;

          :deep(em) {
            background-size: 150% 150%;
          }
        }
      }
    }
  }
}
</style>
