<template>
  <div
    ref="sectionIntroElement"
    class="header-3d"
    :class="{ '-scrolled': isScrolledDown, '-loading': isLoading }"
  >
    <div class="titlewrapper" role="group">
      <p class="eyebrow line">
        {{ data.eyebrow }}
      </p>
      <h1 class="title">
        <span v-if="data.title_line_1" class="span line">{{ data.title_line_1 }}</span><br>
        <span v-if="data.title_line_2" class="span line">{{ data.title_line_2 }}</span>
      </h1>
    </div>
    <ul v-if="availableLocales.length > 1" class="language-switch">
      <li class="listitem">
        <a :class="{ '-active': locale === 'de' }" :href="switchLocalePath('de')" class="link">
          Deutsch
        </a>
      </li>
      <li class="listitem">
        <a :class="{ '-active': locale === 'en' }" :href="switchLocalePath('en')" class="link">
          English
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { type StatamicFieldsetHeader3d } from '~/content/StatamicTypes';
import {
  type Experience,
  type ExperienceLight,
  type ExperienceRegular,
  isExperienceRegular
} from '~/webgl/webglExperience';
import { injectStrict } from '~/common/inject';
import { ControlPanelInjectionKey } from '~/common/useControlPanel';
import animateOnReady from '~/common/animateOnReady';
import getTimelineForElement from '~/common/getTimelineForElement';

const props = defineProps<{
  experience: Experience;
  data: StatamicFieldsetHeader3d;
}>();

const { locale, availableLocales } = useI18n();
const switchLocalePath = useSwitchLocalePath();

const { setControlPanelExtensionLink, changeVisibility, currentPopup } = injectStrict(ControlPanelInjectionKey);

const sectionIntroElement = ref<HTMLElement | null>(null);
const isScrolledDown = ref<boolean>(false);
const isLoading = ref<boolean>(true);
const isSectionVisible = ref(false);
const isStarted = ref(false);

const { start, kill } = handleIsScrolled();
const runningOnLeave: (() => void)[] = [kill];

const runningOnScrolledDown: (() => void)[] = [];
const runningOnScrolledUp: (() => void)[] = [];

const runningOnPopupVisible: (() => void)[] = [];
const runningOnPopupHidden: (() => void)[] = [];

const runningOnSectionVisible: (() => void)[] = [
  () => setControlPanelExtensionLink(null)
];

const runningOnSectionHidden: (() => void)[] = [];

const { stop } = useIntersectionObserver(
  sectionIntroElement,
  ([{ isIntersecting }]) => {
    isSectionVisible.value = isIntersecting;

    if (!isStarted.value) {
      return;
    }

    if (isSectionVisible.value) {
      runningOnSectionVisible.forEach(fn => fn());
    } else {
      runningOnSectionHidden.forEach(fn => fn());
    }
  }
);

watch(currentPopup, (newPopup) => {
  if (newPopup) {
    runningOnPopupVisible.forEach((fn) => {
      fn();
    });
  } else {
    runningOnPopupHidden.forEach((fn) => {
      fn();
    });
  }
});

onMounted(() => {
  animateOnReady(() => animate(props.experience));

  function animate (e: Experience) {
    start();
    isLoading.value = false;
    changeVisibility('-faded-in');

    setTimeout(() => {
      isStarted.value = true;
    }, 750);

    if (!e) {
      return false;
    } else if (isExperienceRegular(e)) {
      animateRegular(e);
    } else {
      animateLight(e);
    }
  }
});

onBeforeUnmount(() => {
  runningOnLeave
    .forEach((fn) => {
      fn();
    });
});

watchEffect(() => {
  if (isScrolledDown.value) {
    runningOnScrolledDown.forEach((fn) => {
      fn();
    });
  } else {
    runningOnScrolledUp.forEach((fn) => {
      fn();
    });
  }
});

function handleIsScrolled () {
  return {
    start,
    kill
  };

  function start () {
    window.addEventListener('scroll', addScrollListener);
  }

  function kill () {
    window.removeEventListener('scroll', addScrollListener);
  }

  function addScrollListener () {
    if (document.body.style.position === 'fixed') {
      return;
    }
    if (window.scrollY >= 10 && !isScrolledDown.value) {
      isScrolledDown.value = true;
    } else if (window.scrollY < 10 && isScrolledDown.value) {
      isScrolledDown.value = false;
    }
  }
}

function animateRegular (e: ExperienceRegular) {
  const lines = Array.from((sectionIntroElement.value as HTMLElement).querySelectorAll('.line'));
  const logoPosition = {
    min: 0,
    max: 50
  };

  const gradientTopPosition = {
    start: {
      z: e.gradients.top.userData.initialPosition.z,
      x: e.gradients.top.userData.initialPosition.x
    },
    end: {
      z: 15,
      x: 30
    }
  };
  const gradientBottomPosition = {
    start: {
      z: e.gradients.bottom.userData.initialPosition.z,
      x: e.gradients.bottom.userData.initialPosition.x
    },
    end: {
      z: 25,
      x: -30
    }
  };

  const timelineScroll = getTimelineForElement(sectionIntroElement.value as HTMLElement, {
    scrub: 1,
    start: 0,
    end: '80%'
  });

  draw();

  if (window.scrollY > 50) {
    timelineScroll.progress(1);
  }

  runningOnScrolledDown.push(fadeLogoIn);
  runningOnScrolledUp.push(fadeLogoOut);
  runningOnLeave.push(kill);
  runningOnLeave.push(e.stop);

  runningOnSectionHidden.push(() => {
    hideScene();
    disableScrollTrigger();
    e.stop();
  });

  runningOnSectionVisible.push(() => {
    e.start();
    enableScrollTrigger();
    setTimeout(() => {
      showScene();
    }, 100);
  });

  runningOnPopupVisible.push(() => {
    if (isSectionVisible.value) {
      return;
    }
    hideScene();
  });
  runningOnPopupHidden.push(showScene);

  function fadeLogoIn () {
    e.logo.container.visible = true;
    gsap
      .to(e.logo.material, {
        opacity: 0.8,
        delay: 0,
        duration: 3
      });
  }

  function fadeLogoOut () {
    gsap.to(
      e.logo.material, {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          if (!isScrolledDown.value) {
            e.logo.container.visible = false;
          }
        }
      });
  }

  function hideScene () {
    if (sectionIntroElement.value) {
      sectionIntroElement.value.style.visibility = 'hidden';
    }
    e.logo.container.visible = false;
    e.gradients.hide({ immediate: true });
  }

  function showScene () {
    if (sectionIntroElement.value) {
      sectionIntroElement.value.style.visibility = 'unset';
    }
    e.logo.container.visible = true;
    e.gradients.show({ immediate: true });
  }

  function disableScrollTrigger () {
    timelineScroll.progress(1);
    timelineScroll.scrollTrigger?.disable(false);
  }

  function enableScrollTrigger () {
    timelineScroll.scrollTrigger?.enable();
  }

  function draw () {
    e.gradients.show();
    e.start();

    timelineScroll
      .to(lines,
        {
          x: -window.innerWidth * 1.5,
          ease: 'Power2.easeIn',
          stagger: -0.05
        })
      .to(e.logo.container.position,
        {
          z: logoPosition.max,
          x: 0,
          duration: 1.5,
          ease: 'SlowMo.ease.config(0.1, 0.8, false)'
        }, '<')
      .to(e.logo.container.rotation,
        {
          y: Math.PI * 1.6,
          duration: 1.5,
          ease: 'SlowMo.ease.config(0.1, 0.8, false)'
        }, '<')
      .to(e.gradients.top.position,
        {
          z: gradientTopPosition.end.z,
          x: gradientTopPosition.end.x,
          ease: 'linear'
        }, '<')
      .to(e.gradients.bottom.position,
        {
          z: gradientBottomPosition.end.z,
          x: gradientBottomPosition.end.x,
          ease: 'linear'
        }, '<');
  }

  function kill () {
    if (timelineScroll) {
      timelineScroll.kill();
    }
    if (sectionIntroElement.value) {
      gsap.killTweensOf(sectionIntroElement.value);
    }

    for (const line of lines) {
      gsap.killTweensOf(line);
    }

    gsap.killTweensOf(e.logo.container.position);
    gsap.killTweensOf(e.logo.container.rotation);
    gsap.killTweensOf(e.gradients.top.position);
    gsap.killTweensOf(e.gradients.bottom.position);

    e.logo.container.visible = false;
    e.gradients.hide({ immediate: true });

    gsap.set(lines, {
      x: 0
    });

    gsap.set(e.logo.container.position, {
      z: logoPosition.min
    });

    gsap.set(e.logo.container.rotation, {
      y: 0
    });

    gsap.set(e.gradients.top.position, {
      z: gradientTopPosition.start.z,
      x: gradientTopPosition.start.x
    });
    gsap.set(e.gradients.bottom.position, {
      z: gradientBottomPosition.start.z,
      x: gradientBottomPosition.start.x
    });
  }
}

function animateLight (e: ExperienceLight) {
}

</script>

<style scoped lang="scss">

.header-3d {
  max-width: 100vw;
  min-height: 100vh;

  @media (--sm) {
    margin-bottom: 12vh;
  }
  @media (--md) {
    margin-bottom: 10vh;
  }
  @media (--lg-up) {
    margin-bottom: 0vh;

  }

  .blur {
    transition: filter 750ms ease-out;
  }

  &:not(.-loading) {
    .blur {
      filter: blur(0px);
    }
  }

  &.-loading {
    cursor: progress;

    > .titlewrapper {
      filter: blur(15px);
    }
  }

  > .titlewrapper {
    padding: 0 var(--page-padding);
    color: #ffffff;
    opacity: 1;
    max-width: 100vw;
    transition: opacity 2500ms;
    position: sticky;
    top: calc(50% - var(--font-size));

    @media (--sm) {
      @include fluid(--font-size, 90);
    }

    @media (--md) {
      @include fluid(--font-size, 150);
    }

    @media (--lg-up) {
      @include fluid(--font-size, 140);
    }

    > .title {

      @media (--sm) {
        @include text-3xl(var(--font-weight-bold));
      }

      @media (--md) {
        @include text-5xl(var(--font-weight-bold));
      }

      @media (--lg-up) {
        @include text-6xl(var(--font-weight-bold));
      }

      line-height: 0.95 !important;

      > .span {
        display: inline-block;
      }
    }

    > .eyebrow {
      @include fluid(padding-bottom, 16);

      @media (--sm) {
        @include text-lg;
      }

      @media (--md-up) {
        @include text-2xl;
      }
    }
  }
}

.language-switch {
  position: absolute;
  inset: 2rem var(--page-padding) 4.5rem auto;
  display: flex;
  height: fit-content;

  > .listitem {

    &:first-of-type {
      &::after {
        content: "";
        position: absolute;
        inset: 0 0 0 auto;
        height: 100%;
        background-color: #ffffff;
        opacity: 0.2;
        width: 0.0625rem;
      }
    }

    > .link {
      @include text-xs();

      padding: 0 0.5rem;
      transition: opacity 200ms;
      color: var(--color-white);
      opacity: 0.7;
      text-decoration: none;

      &:hover {
        opacity: 0.9;
      }

      &.-active {
        opacity: 1;
      }
    }
  }

}

</style>
