export function usePlausibleTracking (pageSlug: string) {
  if (!plausible) {
    return;
  }
  const scrollDepths = [25, 50, 75, 100];
  const scrollEventsSent: number[] = [];

  window.addEventListener('scroll', function () {
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPercentage = (scrollPosition / documentHeight) * 100;

    scrollDepths.forEach(function (depth) {
      if (scrollPercentage >= depth && !scrollEventsSent.includes(depth)) {
        plausible('Scroll Depth', { props: { percentage: depth, page: pageSlug } });

        scrollEventsSent.push(depth);
      }
    });
  });
}
