<template>
  <div class="content-video">
    <figure v-if="data.video_inline" class="figure">
      <video
        :poster="videoPoster ? videoPoster.default : ''"
        class="video"
        loading="lazy"
        preload="none"
        :src="useCloudfrontUrl(data.video_inline.permalink)"
        :muted="data.video_autoplay"
        :autoplay="data.video_autoplay"
        :loop="data.video_loop"
        :controls="data.video_controls"
        playsinline
      />
    </figure>
  </div>
</template>

<script setup lang="ts">

import { type StatamicFieldsetContentVideo } from '~/content/StatamicTypes';
import useCloudfrontUrl from '~/common/useCloudfrontUrl';
import useStatamicThumbnail from '~/content/useStatamicThumbnail';

const props = defineProps<{
  data: StatamicFieldsetContentVideo;
}>();

const videoPoster = computed(() => {
  const imageId = props.data.video_poster_image?.id;
  if (imageId) {
    return useStatamicThumbnail(imageId, null);
  }
  return null;
});

</script>

<style scoped lang="scss">

.content-video {

  @media (--lg-down) {
    padding: 3.75rem var(--page-padding);
  }
  @media (--lg-up) {
    padding: 9rem var(--page-padding);
  }

  > .figure {

    >.video {
      max-height: 100lvh;
      border-radius: var(--base-border-radius);
      overflow: clip;
    }
  }
}
</style>
