<template>
  <div ref="containerElement" class="projects-simple">
    <h2 v-if="data.title" class="title">
      {{ data.title }}
    </h2>
    <div ref="projectsElement" class="projects" :style="{ '--count': projects.length }">
      <ProjectTeaserSimple
        v-for="p in projects"
        :key="p.id"
        class="teaser"
        :title="p.title"
        :asset="p.project_teaser"
        :slug="p.slug"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { type StatamicFieldsetProjectsSimple } from '~/content/StatamicTypes';
import { injectStrict } from '~/common/inject';
import { StatamicLinksInjectionKey, type StatamicProjectLink } from '~/content/statamicLinks';
import ProjectTeaserSimple from '~/components/ProjectTeaserSimple.vue';

const props = defineProps<{
  data: StatamicFieldsetProjectsSimple;
}>();

const containerElement = ref<HTMLElement | null>(null);
const projectsElement = ref<HTMLElement | null>(null);

const { links } = injectStrict(StatamicLinksInjectionKey);

const runningOnLeave: (() => void)[] = [];

const selectedProjectIds = computed((): string[] => {
  return props.data.projects.map(i => i.id);
});

const projects = computed((): StatamicProjectLink[] => {
  return links.projects.filter(i => selectedProjectIds.value.includes(i.id)).filter((i) => {
    return i.project_teaser && i.project_teaser.length;
  });
});

onMounted(function () {
  if (projectsElement.value && containerElement.value) {
    const animation = sliderAnimation(projectsElement.value);

    runningOnLeave.push(animation.kill);
  }
});

onBeforeUnmount(() => {
  runningOnLeave
    .forEach((fn) => {
      fn();
    });
});

function sliderAnimation (sliderElement: HTMLElement) {
  const tl = gsap.timeline({
    paused: true
  });

  tl.to(sliderElement, {
    x: () => -(sliderElement?.scrollWidth - window.innerWidth) + 'px',
    ease: 'none',
    scrollTrigger: {
      trigger: sliderElement,
      end: () => '+=' + sliderElement.offsetWidth,
      scrub: 0.5,
      pin: true,
      invalidateOnRefresh: true
    }
  });

  return { kill };
  function kill () {
    tl.kill();
  }
}

</script>

<style scoped lang="scss">

.projects-simple {
  padding: 0 var(--page-padding);
  overflow-x: clip;

  > .title {
    padding-left: 8rem;
    padding-right: 8rem;

    @media (--lg-down) {
      @include text-xl(500);
      @include fluid(padding-bottom, 36);
    }

    @media (--lg-up) {
      @include text-3xl(500);
      @include fluid(padding-bottom, 48);
    }
  }

  .projects {
    --count: 1;

    width: calc(25vw + var(--count) * calc(var(--teaser-width) + var(--gap)));
    display: flex;
    gap: var(--gap);
    height: 100vh;
    align-items: center;
    padding-right: 25vw;

    @media (--sm) {
      --teaser-width: 75vw;
      --gap: 2.5rem;
    }
    @media (--md) {
      --teaser-width: 40vw;
      --gap: 4rem;
    }
    @media (--lg-up) {
      --teaser-width: 25vw;
      --gap: 5rem;
    }

    > .teaser {
      width: var(--teaser-width);
      flex-shrink: 0;

      > .title {
      }
    }
  }
}
</style>
