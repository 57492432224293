<template>
  <div ref="headerElement" class="header-default">
    <div class="content">
      <p ref="eyebrowElement" class="eyebrow">
        {{ props.data.eyebrow }}
      </p>
      <h1 ref="titleElement" class="title">
        {{ props.data.title }}
      </h1>
    </div>
    <div class="blobs">
      <img class="blob -top" src="/images/header-shape-blue-top.svg" alt="">
      <img class="blob -bottom" src="/images/header-shape-blue-bottom.svg" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import { type StatamicFieldsetHeaderDefault } from '~/content/StatamicTypes';
import getTimelineForElement from '~/common/getTimelineForElement';

const props = defineProps<{
  data: StatamicFieldsetHeaderDefault;
}>();

const headerElement = ref<null | HTMLElement>(null);
const eyebrowElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);

onMounted(function () {
  const { $Splitting } = useNuxtApp();

  if (titleElement.value && headerElement.value && eyebrowElement.value) {
    // @ts-ignore
    const [splitting]: Splitting.Result[] = $Splitting({
      target: titleElement.value,
      by: 'lines',
      key: null
    });

    animateLines(splitting.lines ?? [], eyebrowElement.value, headerElement.value);
  }
});

function animateLines (lines: HTMLElement[][], eyebrowElement: HTMLElement, trigger: HTMLElement) {
  const line1 = lines[0] ?? [];
  const line2 = lines[1] ?? [];

  const timelineScroll = getTimelineForElement(trigger, {
    scrub: 1,
    start: 0,
    end: '50%'
  });

  timelineScroll
    .to(line2,
      {
        x: -window.innerWidth * 1.5,
        ease: 'Power2.easeIn'
      })
    .to(line1,
      {
        x: -window.innerWidth * 1.5,
        ease: 'Power2.easeIn'
      }, '<+=25%')
    .to(eyebrowElement,
      {
        x: -window.innerWidth * 1.5,
        ease: 'Power2.easeIn'
      }, '<+=25%');
}

</script>

<style scoped lang="scss">

.header-default {
  padding: var(--page-padding);
  display: flex;
  align-items: center;

  @media (--sm) {
    height: 80svh;
  }
  @media (--md) {
    height: 85svh;
  }
  @media (--lg-up) {
    height: 110svh;
  }

  > .content {
    > .eyebrow {
      @include fluid(padding-bottom, 16);

      @media (--sm) {
        @include text-base;
      }

      @media (--md-up) {
        @include text-lg;
      }
    }

    > .title {
      @media (--sm) {
        @include text-2xl(var(--font-weight-bold));
      }

      @media (--md) {
        @include text-4xl(var(--font-weight-bold));
      }

      @media (--lg) {
        @include text-5xl(var(--font-weight-bold));
      }
      @media (--xl) {
        @include text-6xl(var(--font-weight-bold));
      }
    }
  }

  > .blobs {
    z-index: -1;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    > .blob {
      position: absolute;

      &.-top {
        inset: 0 auto auto 0;
      }

      &.-bottom {
        inset: auto 0 -60svh auto;
      }
    }
  }
}

</style>
