<template>
  <NuxtLink
    :to="localePath(`/projects/${slug}`)"
    class="project-teaser-simple"
  >
    <picture
      class="picture"
    >
      <source :srcset="image.srcset">
      <img
        class="image"
        :src="image.src"
        alt=""
      >
    </picture>
    <p class="title">
      {{ title }}
    </p>
  </NuxtLink>
</template>

<script setup lang="ts">

import { type StatamicBaseAsset } from '~/content/StatamicTypes';
import useStatamicImage from '~/common/useStatamicImage';

const props = defineProps<{
  title: string;
  slug: string;
  asset: {
    asset: StatamicBaseAsset[];
    type: 'teaser';
  }[];
}>();

const teaserAsset = computed(() => {
  return props.asset?.[0]?.asset[0] ?? null;
});

const { data: image } = await useAsyncData(
  `project-teaser-simple-${teaserAsset.value.asset.id}`,
  (): Promise<{ src: string; srcset: string }> => useStatamicImage(teaserAsset.value.asset.id, '3:4')
);

const localePath = useLocalePath();

</script>

<style scoped lang="scss">

.project-teaser-simple {
  display: block;
  text-decoration: none;

  > .picture {
    > .image {
      border-radius: var(--base-border-radius);
    }
  }

  > .title {
    @include text-xs;

    color: var(--color-font);
    text-decoration: none;
    padding-top: 1.5rem;
  }
}

</style>
