<template>
  <div class="content-testimonials">
    <div v-if="activeHead && activeHead.logo" class="logo">
      <img
        :src="useCloudfrontUrl(activeHead.logo.permalink)"
        class="image"
        alt=""
      >
      <p v-if="activeHead.role && activeHead.name" class="name">
        {{ activeHead.name }}, {{ activeHead.role }}
      </p>
    </div>
    <div v-if="activeHead" class="statement">
      <p class="text">
        "{{ activeHead.statement }}"
      </p>
    </div>
    <div class="testimonials">
      <abbr
        v-for="(t, index) in data.testimonials"
        :key="`${t.name}-${index}`"
        :class="{ '-active': activeHead === t }"
        class="head"
        :title="t.name"
        @click="activeHead = t"
      >
        <StatamicImage
          v-if="t.image"
          :id="t.image.id"
          class="image"
          cropping="original"
          :id-mobile="undefined"
          :alt="t.name"
        />
        <span v-else class="placeholder" />
      </abbr>
    </div>
  </div>
</template>

<script setup lang="ts">

import { type StatamicFieldsetTestimonial, type StatamicFieldsetTestimonials } from '~/content/StatamicTypes';
import StatamicImage from '~/common/StatamicImage.vue';
import useCloudfrontUrl from '~/common/useCloudfrontUrl';

const props = defineProps<{
  data: StatamicFieldsetTestimonials;
}>();

const activeHead = ref<null | StatamicFieldsetTestimonial>(props.data.testimonials[0]);

</script>

<style scoped lang="scss">

.content-testimonials {
  @media (--lg-down) {
    padding: 3.75rem var(--page-padding);
  }
  @media (--lg-up) {
    padding: 9rem var(--page-padding);
  }

  > .logo {
    margin: auto;
    width: fit-content;

    > .image {
      filter: grayscale(1);
      width: auto;
      height: 5rem;
      margin: auto;
    }

    >.name {
      width: auto;
      text-align: center;

      @media (--lg-down) {
        @include text-base();
      }
      @media (--lg-up) {
        @include text-lg();
      }
    }
  }

  > .statement {
    max-width: 60ch;
    margin: auto;

    @media (--lg-down) {
      padding: 1.5rem 0;
    }
    @media (--lg-up) {
      padding: 4.5rem 0;
    }

    > .text {
      text-align: center;

      @media (--lg-down) {
        @include text-base(500);
      }
      @media (--lg-up) {
        @include text-2xl(500);
      }
    }
  }

  > .testimonials {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--lg-down) {
      --size: 3.5rem;
      --outline-offset: 0.4rem;

      gap: 1.25rem;
      padding: 1rem 0;

    }
    @media (--lg-up) {
      --size: 5rem;
      --outline-offset: 0.7rem;

      gap: 2.5rem;
      padding: 2rem 0;
    }

    > .head {
      display: inline-block;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      overflow: clip;
      cursor: pointer;

      &:hover {
        outline: 1px solid rgba(255, 255, 255, 0.37);
        outline-offset: var(--outline-offset);
      }

      &.-active {
        outline: 1px solid white;
        outline-offset: var(--outline-offset);
      }

      > .image {
      }

      > .placeholder {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #3a3a3a;
      }
    }
  }
}
</style>
