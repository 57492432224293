<template>
  <div class="the-gridonic-way">
    <p v-if="data.title" class="title">
      {{ data.title }}
    </p>
    <div class="items">
      <div
        v-for="i in theGridonicWay.items"
        :key="i.title"
        class="item"
        :class="{ '-open': openItem === i }"
      >
        <p class="title" @click="toggleItem(i)">
          {{ i.title }}
        </p>
        <div class="description" v-html="i.description" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { type StatamicFieldsetTheGridonicWay } from '~/content/StatamicTypes';
import { injectStrict } from '~/common/inject';
import { StatamicTheGridonicWayInjectionKey, type StatamicTheGridonicWayItem } from '~/content/statamicTheGridonicWay';

const props = defineProps<{
  data: StatamicFieldsetTheGridonicWay;
}>();

const { theGridonicWay } = injectStrict(StatamicTheGridonicWayInjectionKey);

const openItem = ref<StatamicTheGridonicWayItem | null>(null);

function toggleItem (i: StatamicTheGridonicWayItem) {
  if (window.matchMedia('(min-width: 900px)').matches) {
    return;
  }
  if (openItem.value === i) {
    openItem.value = null;
  } else {
    openItem.value = i;
  }
}

</script>

<style scoped lang="scss">

.the-gridonic-way {
  @media (--lg-down) {
    padding: 3.75rem var(--page-padding);
  }
  @media (--lg-up) {
    padding: 9rem var(--page-padding);
  }

  > .title {
    @media (--lg-down) {
      @include text-xl(500);
      @include fluid(padding-bottom, 36);
    }

    @media (--lg-up) {
      @include text-3xl(500);
      @include fluid(padding-bottom, 48);
    }
  }

  > .items {

    @media (--lg-down) {
      padding: 0 0 2rem 0;
    }

    @media (--lg-up) {
      padding: 2rem 0;
    }

    > .item {
      @media (--lg-down) {
        color: #5f5f5f;
        transition: color 350ms;

        &.-open {
          color: #ffffff;

          > .title {
            &::after {
              rotate: 0deg;
            }
          }

          > .description {
            max-height: 100lvh;
          }
        }

        > .title {
          @include text-lg(500);

          padding: 1.6rem 0;
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            content: "";
            width: 1.06rem;
            height: 0.53rem;
            inset: 0 0 0 auto;
            background-color: currentColor;
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='12' viewBox='0 0 21 12' fill='none'%3E%3Cpath d='M18.9814 10.5L10.4814 2L1.98145 10.5' stroke='white' stroke-width='2.19988' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            mask-repeat: no-repeat;
            mask-size: 100%;
            transition: background-color 350ms, rotate 350ms;
            rotate: 180deg;
          }
        }

        > .description {
          @include text-sm(500);

          max-height: 0;
          overflow: hidden;
          transition: max-height 250ms;
        }
      }
      @media (--lg-up) {
        display: grid;
        grid-template-columns: 35ch minmax(0, auto);
        gap: 5rem;
        grid-template-rows: 10rem;
        color: #5f5f5f;
        transition: color 550ms;
        cursor: pointer;

        &:hover {
          color: #ffffff;

          > .description {
            opacity: 1;
          }
        }

        > .title {
          @include text-2xl(500);
        }

        > .description {
          @include text-lg(500);

          transition: opacity 550ms;
          opacity: 0;
        }
      }

    }
  }
}

</style>
