<template>
  <NuxtLink
    class="link-wrapper"
    role="group"
    :aria-label="`Project ${shortTitle}`"
    :to="localePath(`/projects/${slug}`)"
    data-project
    :data-name="shortTitle"
  >
    <div
      ref="teaserElement"
      class="project-teaser-big"
      :class="{ '-was-in-viewport': isFirstVisible }"
    >
      <div ref="imageElementDevice" class="figure -device">
        <picture v-if="statamicImageLeft" role="presentation" class="picture">
          <source
            v-if="statamicImageLeftMobile"
            media="(max-width: 599px)"
            :srcset="statamicImageLeftMobile.srcset"
          >
          <source
            media="(min-width: 600px)"
            :srcset="statamicImageLeft.srcset"
          >
          <img
            loading="lazy"
            class="image"
            :src="statamicImageLeft.src"
            alt=""
          >
        </picture>
      </div>
      <div ref="imageElementMood" class="figure -mood" :class="[ `-title-${positionText}` ]">
        <p class="title">
          {{ title }}
        </p>
        <picture v-if="statamicImageRight" role="presentation" class="picture">
          <source
            v-if="statamicImageRightMobile"
            media="(max-width: 599px)"
            :srcset="statamicImageRightMobile.srcset"
          >
          <source
            media="(min-width: 600px)"
            :srcset="statamicImageRight.srcset"
          >
          <img
            loading="lazy"
            class="image"
            :src="statamicImageRight.src"
            alt=""
          >
        </picture>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { type StatamicBaseAsset } from '~/content/StatamicTypes';
import { injectStrict } from '~/common/inject';
import { ControlPanelInjectionKey } from '~/common/useControlPanel';
import useStatamicImage from '~/common/useStatamicImage';

const props = defineProps<{
  imageLeft: StatamicBaseAsset[];
  imageRight: StatamicBaseAsset[];
  title: string;
  shortTitle: string;
  slug: string;
  positionText: 'top' | 'bottom';
}>();

const localePath = useLocalePath();

const { setControlPanelExtensionLink } = injectStrict(ControlPanelInjectionKey);
const runningOnLeave: (() => void)[] = [];

const isVisible = ref<boolean>(false);
const isFirstVisible = ref<boolean>(false);
const teaserElement = ref<HTMLElement | null>(null);
const imageElementDevice = ref<HTMLElement | null>(null);
const imageElementMood = ref<HTMLElement | null>(null);

/* asset left */
const assetLeft = props.imageLeft[0] ?? null;
const assetIdLeft = String(assetLeft?.asset?.id);
const assetCroppingLeft = assetLeft?.cropping?.value ?? 'original';

const { data: statamicImageLeft } = await useAsyncData(
  `statamic-image-left-${assetIdLeft}`,
  (): Promise<{ src: string; srcset: string }> => useStatamicImage(assetIdLeft, assetCroppingLeft)
);

const { data: statamicImageLeftMobile } = await useAsyncData(
  `statamic-image-left-${assetIdLeft}-mobile`,
  (): Promise<{ src: string; srcset: string }> => useStatamicImage(assetIdLeft, 'wide')
);

/* asset right */
const assetRight = props.imageRight[0] ?? null;
const assetIdRight = String(assetRight?.asset?.id);
const assetCroppingRight = assetRight?.cropping?.value ?? 'original';

const { data: statamicImageRight } = await useAsyncData(
  `statamic-image-right-${assetIdRight}`,
  (): Promise<{ src: string; srcset: string }> => useStatamicImage(assetIdRight, assetCroppingRight)
);
const { data: statamicImageRightMobile } = await useAsyncData(
  `statamic-image-right-${assetIdRight}-mobile`,
  (): Promise<{ src: string; srcset: string }> => useStatamicImage(assetIdRight, 'wide')
);

const { stop } = useIntersectionObserver(
  teaserElement,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;

    if (isVisible.value) {
      setControlPanelExtensionLink({ title: props.title, route: `/projects/${props.slug}` });
      isFirstVisible.value = true;
    }
  },
  {
    threshold: 0.3,
    rootMargin: '-100px'
  }
);

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

function isSmallDevice () {
  return window.matchMedia('(max-width: 599px)').matches;
}

</script>

<style scoped lang="scss">

.link-wrapper {
  text-decoration: none;

  @media (--sm) {
    margin: calc(1.5 * var(--page-padding)) var(--page-padding);
  }
  @media (--md-up) {
    margin: calc(0.5 * var(--page-padding)) var(--page-padding);
  }
}

.project-teaser-big {
  width: 90vw;
  display: flex;
  gap: 4% 2rem;

  &.-was-in-viewport {
    --scale-text-y: 0%;
    --inset-x: 0%;
    --inset-y: 0%;

    > .figure {
      > .title {
        transform: translateY(0);
      }
    }
  }

  &:not(.-was-in-viewport) {
    --inset-x: 10%;
    --inset-y: 10%;
    --scale-text-y: 100%;
  }

  @media (--sm) {
    padding: 10% 0;
    flex-direction: column;
    overflow-x: hidden;

    > .figure {
      transform-origin: 0 0;
      margin: 0 0 1rem 0;

      &.-device {
        --scaleY: 1.4;
      }

      &.-mood {
        --scaleY: 0.6;

        display: flex;
        flex-direction: column-reverse;

        > .picture {
          transition-delay: 350ms;
        }
      }

      > .picture {
        width: 100%;
        transition: clip-path 650ms;
        clip-path: inset(var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round 8px);

        > .image {
          width: 100%;
          height: calc(var(--scaleY) * 25vh);
          object-fit: cover;
          border-radius: var(--base-border-radius);
          object-position: center;
        }
      }

      > .title {
        @include text-lg;

        line-height: 1.1;
        white-space: nowrap;
        color: var(--color-font);
        text-decoration: none;
        padding: var(--page-padding) 0;
        text-align: right;
        width: 100%;
        transform: none !important;
        clip-path: inset(var(--scale-text-y) 0% 0% 0%);
        transition: clip-path 1000ms;
        transition-delay: 350ms;
      }
    }
  }

  @media (--md-up) {
    padding: 7.5% 0;

    > .figure {
      &.-device {
        width: 58%;

        > .picture {
          transition-delay: 0ms;
          transition-duration: 650ms;
        }
      }

      &.-mood {
        width: 38%;

        > .picture {
          transition-delay: 250ms;
          transition-duration: 650ms;
        }
      }

      > .picture {
        transition-property: clip-path;
        clip-path: inset(var(--inset-y) var(--inset-x) var(--inset-y) var(--inset-x) round 8px);

        > .image {
          max-height: 60vh;
          width: 100%;
          object-fit: cover;
          border-radius: var(--base-border-radius);
        }
      }

      > .title {
        display: block;
        white-space: nowrap;
        color: var(--color-font);
        text-decoration: none;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        width: 100%;
        text-align: right;
        direction: rtl;
        clip-path: inset(var(--scale-text-y) 0% 0% -100%);
        transition: clip-path 1000ms;
        transition-delay: 350ms;

        @media (--md) {
          @include text-2xl;
        }
        @media (--lg-up) {
          @include text-2xl;
        }
      }
    }
  }
}

</style>
